import {
  convertDecimalWithComma,
  dateToDateString,
  ellipsisString
} from "@/app/infrastructures/misc/Utils";
import { PrintData } from "@/domain/entities/UpdateDelivery";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

let doc: any;

const didDrawCellData = (cell: any) => {
  // top-border
  if (cell.row.cells[0].text[0] !== "" && cell.section === "body") {
    doc.setFillColor("#e0e0e0");
    doc.rect(
      cell.row.cells[0].x - 0.05,
      cell.row.cells[0].y - 0.05,
      190,
      0.2,
      "F"
    );
    doc.setFillColor("#fff");
  }
  if (
    cell.column.dataKey === "recipientName" &&
    cell.row.section === "body" &&
    cell.row.cells[0].text[0]
  ) {
    doc.setFillColor("#fff");
    cell.row.cells[4].text
      .filter((item: string) =>
        cell.row.cells[4].raw.split("\n")[0].includes(item)
      )
      .forEach((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells[4].x,
          cell.row.cells[4].y + 3 * (0.944 * (index + 1) + 0.0562),
          cell.row.cells[4].contentWidth,
          4,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
    doc.setFont("helvetica", "bold");
    doc.setFontSize(7);
    doc.text(
      cell.row.cells[4]?.raw.split("\n")[0],
      cell.row.cells[4]?.x + 2,
      cell.row.cells[4]?.y + 6,
      { maxWidth: 35 }
    );
    doc.setFont("helvetica", "normal");
  }
};

const willDrawCellData = (cell: any) => {
  if (
    cell.column.dataKey === "nameSignature" &&
    (cell.row.section === "head" || cell.row.section === "body")
  ) {
    cell.row.cells[7].styles = {
      ...cell.row.cells[7].styles,
      halign: "center"
    };
  }
  if (
    cell.column.dataKey === "receivedTime" &&
    (cell.row.section === "head" || cell.row.section === "body")
  ) {
    cell.row.cells[8].styles = {
      ...cell.row.cells[8].styles,
      halign: "center"
    };
  }
  // rectangle behind product
  if (
    cell.column.dataKey === "productType" &&
    cell.row.section === "body" &&
    cell.row.cells[1].text.length
  ) {
    const highestCell = cell.row.cells[1].text.length;
    doc.setFillColor("#ebecf0");
    doc.roundedRect(
      cell.row.cells[2].x,
      cell.row.cells[2].y + (1.421 * highestCell + 1.879),
      cell.row.cells[2].contentWidth,
      4,
      1,
      1,
      "F"
    );
    doc.setFillColor("#fff");
  }
  // rectangle behind origin
  if (
    cell.column.dataKey === "origin" &&
    cell.row.section === "body" &&
    cell.row.cells[1].text.length
  ) {
    const highestCell = cell.row.cells[1].text.length;
    doc.setFillColor("#ebecf0");
    doc.roundedRect(
      cell.row.cells[5].x,
      cell.row.cells[5].y + (1.421 * highestCell + 1.879),
      cell.row.cells[5].contentWidth,
      4,
      1,
      1,
      "F"
    );
    doc.setFillColor("#fff");
  }
  // rectangle behind destination
  if (
    cell.column.dataKey === "destination" &&
    cell.row.section === "body" &&
    cell.row.cells[1].text.length
  ) {
    const highestCell = cell.row.cells[1].text.length;
    doc.setFillColor("#ebecf0");
    doc.roundedRect(
      cell.row.cells[6].x,
      cell.row.cells[6].y + (1.421 * highestCell + 1.879),
      cell.row.cells[6].contentWidth,
      4,
      1,
      1,
      "F"
    );
    doc.setFillColor("#fff");
  }
};

const manifestPdf = async (dataHead: PrintData, data: any[], logo: string) => {
  doc = new jsPDF("p", "mm", "a4", true);
  const pdfWidth = doc.internal.pageSize.width;
  const logoProperties = doc.getImageProperties(logo);
  const logoAspectRatio = logoProperties.height / logoProperties.width;

  const imageWidth = 40;
  const imageHeight = logoAspectRatio * 40;
  doc.addImage(
    logo,
    "PNG",
    pdfWidth - imageWidth - 9,
    11,
    imageWidth,
    imageHeight,
    "logo",
    "FAST",
    0
  );
  doc.setFont("helvetica", "bold");
  doc.setFontSize(16);
  doc.setTextColor("#4d4d4d");
  doc.text("Delivery Manifest", 10, 18);
  doc.setFillColor("#e0e0e0");

  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);

  // left column
  doc.setTextColor("#4d4d4d");
  doc.setFillColor("#ebecf0");

  doc.text("Dikirim Oleh", 10, 35);
  doc.text(
    `: ${dataHead.deliveryMasterPartnerType} - ${ellipsisString(
      dataHead.deliveryMasterPartnerName,
      38
    )}`,
    38,
    35
  );

  doc.text("Kota Tujuan", 10, 42);
  doc.text(
    `: ${dataHead.deliveryMasterDestinationCityCode} - ${dataHead.deliveryMasterDestinationCityName}`,
    38,
    42
  );

  doc.text("Tanggal DEL", 10, 49);
  doc.text(
    `: ${dateToDateString(new Date(dataHead.deliveryMasterCreatedAt))}`,
    38,
    49
  );

  doc.text("Nama Kurir", 10, 56);
  doc.text(
    `: ${dataHead.deliveryMasterDriverName} (${dataHead.deliveryMasterDriverPhone})`,
    38,
    56
  );

  // right column
  doc.setTextColor("#4d4d4d");
  doc.setFillColor("#ebecf0");

  doc.text("Total STT", 115, 35);
  doc.text(`: ${dataHead.deliveryMasterTotalStt}`, 142, 35);

  doc.text("Total Koli", 115, 42);
  doc.text(`: ${dataHead.deliveryMasterTotalPiece}`, 142, 42);

  doc.text("Total Berat Kotor", 115, 49);
  doc.text(
    `: ${convertDecimalWithComma(
      dataHead.deliveryMasterTotalGrossWeight,
      2
    )} Kg`,
    142,
    49
  );

  doc.text("Total Berat Dimensi", 115, 56);
  doc.text(
    `: ${convertDecimalWithComma(
      dataHead.deliveryMasterTotalVolumeWeight,
      2
    )} Kg`,
    142,
    56
  );

  let lastPage = 0;
  let lastY = 0;
  let lastHeight = 0;
  const dataLength = data.length;
  doc.autoTable({
    body: data,
    columns: [
      { header: "No.", dataKey: "no" },
      {
        header: "No. STT",
        dataKey: "sttNo"
      },
      { header: "Produk", dataKey: "productType" },
      { header: "Koli", dataKey: "totalPiece" },
      { header: "Penerima", dataKey: "recipientName" },
      { header: "Origin", dataKey: "origin" },
      { header: "Dest.", dataKey: "destination" },
      { header: "Biaya COD", dataKey: "sttCodAmount" },
      { header: "Nama &\nTTD Penerima", dataKey: "nameSignature" },
      { header: "Tanggal\nDiterima", dataKey: "receivedTime" },
      { header: "Catatan", dataKey: "information" }
    ],
    theme: "plain",
    startY: doc.internal.getCurrentPageInfo().pageNumber === 1 ? 66 : 0,
    margin: { top: 7, left: 10, right: 10, bottom: 7 },
    headStyles: {
      font: "helvetica",
      fontStyle: "bold",
      fillColor: "#f5f6f7",
      textColor: "#1a1421",
      fontSize: 6,
      halign: "left",
      cellPadding: { top: 4, right: 2, bottom: 4, left: 2 }
    },
    bodyStyles: {
      font: "helvetica",
      fontStyle: "normal",
      textColor: "#1a1421",
      fontSize: 7,
      halign: "left",
      valign: "top",
      cellPadding: { top: 4, right: 2, bottom: 4, left: 2 },
      cellWidth: "auto"
    },
    columnStyles: {
      0: { cellWidth: 8 },
      1: { cellWidth: 28 },
      2: { cellWidth: 20 },
      3: { cellWidth: 9 },
      4: { cellWidth: 35 },
      5: { cellWidth: 13 },
      6: { cellWidth: 13 },
      7: { cellWidth: 16 },
      8: { cellWidth: 18 },
      9: { cellWidth: 15 },
      10: { cellWidth: 15 }
    },
    didDrawCell: (cell: any) => didDrawCellData(cell),
    willDrawCell: (cell: any) => {
      willDrawCellData(cell);
      lastPage = cell.pageNumber;
      lastY = cell.cursor.y;
      lastHeight = cell.row.height;
      if (cell.row.index === dataLength - 2 && lastY + lastHeight + 20 > 270) {
        cell.cell.contentHeight = 100;
        cell.row.height = 100;
      }
    }
  });
  lastY = lastY + lastHeight + 20;
  lastY = lastY > 270 ? 270 : lastY;
  doc.setPage(lastPage);
  doc.setFontSize(8);
  doc.text("Kurir", 50 + 90, lastY);
  doc.text("(.................................)", 40 + 90, lastY + 20);
  doc.text("Operations Supervisor", 80 + 90, lastY);
  doc.text("(.................................)", 80 + 90, lastY + 20);

  doc.setProperties({
    title: "Delivery Manifest"
  });

  doc.autoPrint();

  window.open(doc.output("bloburl"), "_blank");
};

export default manifestPdf;
