
import { Vue, Options } from "vue-class-component";
import { UpdateDeliveryController } from "@/app/ui/controllers/UpdateDeliveryController";

@Options({
  components: {},
  emits: ["close"]
})
export default class UpdateDeliveryFormDeleteStt extends Vue {
  // delete stt pieces
  sttNumberListTrash: Array<string> = [];
  sttNumberToDelete = "";
  sttNumberSuccessDelete = false;
  errorSttNumberDelete = "";
  async onDeleteSttNumber() {
    if (this.sttNumberToDelete) {
      this.errorSttNumberDelete = "";
      const sttNo = this.sttNumberToDelete.split("#")[0];
      const resultDelete = await UpdateDeliveryController.removeSttListNoToUpdate(
        sttNo
      );
      if (!resultDelete) {
        this.errorSttNumberDelete =
          "No. STT tidak ditemukan atau sudah terhapus";
        return;
      }
      this.sttNumberListTrash.push(sttNo);
      this.sttNumberSuccessDelete = true;
      this.sttNumberToDelete = "";
    }
    const deleteStt: any = this.$refs.deleteStt;
    deleteStt.focus();
  }
  onShowDelete() {
    this.errorSttNumberDelete = "";
    this.sttNumberToDelete = "";
    this.sttNumberSuccessDelete = false;
    this.sttNumberListTrash = [];
  }
}
