import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("img", {
      id: "logo",
      src: require(`@/app/ui/assets/images/logo-${_ctx.roleAccount}.png`),
      class: "hidden",
      alt: "logo"
    }, null, 8, ["src"])
  ]))
}