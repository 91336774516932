import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pt-8 bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative w-full" }
const _hoisted_2 = { class: "hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_update_delivery_form_section = _resolveComponent("update-delivery-form-section")!
  const _component_update_delivery_form_table_stt = _resolveComponent("update-delivery-form-table-stt")!
  const _component_DetailLayout = _resolveComponent("DetailLayout")!
  const _component_PrintManifest = _resolveComponent("PrintManifest")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Breadcrumb),
    _createVNode(_component_DetailLayout, {
      onBack: _ctx.goBack,
      title: "Update DEL",
      "vertical-separator": false,
      fullPage: "",
      "leave-page-confirmation": _ctx.leavePageConfirmation,
      "onUpdate:leave-page-confirmation": _cache[1] || (_cache[1] = ($event: any) => (_ctx.leavePageConfirmation = $event)),
      onLeavePage: _ctx.onLeavePage,
      loading: _ctx.isLoading,
      "is-floating-action": ""
    }, {
      data: _withCtx(() => [
        _createVNode(_component_update_delivery_form_section),
        _createVNode(_component_update_delivery_form_table_stt, { onProcess: _ctx.onUpdateDelConfirmation }, null, 8, ["onProcess"])
      ]),
      _: 1
    }, 8, ["onBack", "leave-page-confirmation", "onLeavePage", "loading"]),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_PrintManifest, { ref: "print" }, null, 512)
    ])
  ]))
}