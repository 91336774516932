
import { mixins, Options } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import OutGoingMixins from "@/app/ui/views/out-going-shipment/out-going-mixin";
import UpdateDeliveryFormSection from "@/app/ui/views/incoming-process/update-delivery/update-delivery-form/update-delivery-form-section.vue";
import UpdateDeliveryFormTableStt from "@/app/ui/views/incoming-process/update-delivery/update-delivery-form/update-delivery-form-table-stt.vue";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import { UpdateDeliveryController } from "@/app/ui/controllers/UpdateDeliveryController";
import { CreateUpdateDelivery } from "@/data/payload/api/UpdateDeliveryReqeust";
import PrintManifest from "@/app/ui/views/incoming-process/update-delivery/modules/print.vue";
import { removeDataLocalStorage } from "@/app/infrastructures/misc/Utils";
import { CreateUpdateDeliveryResponse } from "@/domain/entities/UpdateDelivery";

@Options({
  components: {
    UpdateDeliveryFormTableStt,
    UpdateDeliveryFormSection,
    DetailLayout,
    PrintManifest
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.answerLeavingPage) {
      next();
    } else {
      this.leavePageConfirmation = true;
      this.nextPath = to.path;
      next(false);
    }
  }
})
export default class UpdateDeliveryForm extends mixins(OutGoingMixins) {
  mounted() {
    UpdateDeliveryController.setNotification(false);

    this.form.listSttNoToUpdate = JSON.parse(
      localStorage.getItem("update-delivery") || "[]"
    );
  }
  unmounted() {
    UpdateDeliveryController.resetForm();
  }

  goBack() {
    this.$router.push("/update-delivery");
  }

  // leave page confirmation
  leavePageConfirmation = false;
  nextPath = "";
  answerLeavingPage = false;
  onLeavePage() {
    this.answerLeavingPage = true;
    this.$router.push(this.nextPath);
    removeDataLocalStorage("update-delivery");
    UpdateDeliveryController.setPaid(true);
  }

  // logic process update del
  onUpdateDelConfirmation(withPrint: boolean) {
    UpdateDeliveryController.setNotification(false);
    // show confirmation
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: "Konfirmasi Pembaruan",
        message: "Pastikan kembali, seluruh detail STT sudah benar dan sesuai.",
        textCancel: "Kembali",
        textSuccess: "Process",
        onSubmit: () => this.onUpdateDel(withPrint),
        onClose: () => MainAppController.closeMessageModal(),
        image: "are-you-sure"
      })
    );
  }
  get form() {
    return UpdateDeliveryController.form;
  }
  async onUpdateDel(withPrint: boolean) {
    // close confirmation
    MainAppController.closeMessageModal();
    // call post update delivery
    const responseCreate:
      | CreateUpdateDeliveryResponse
      | boolean = await UpdateDeliveryController.createUpdateDelivery(
      new CreateUpdateDelivery(
        this.form.deliveredBy.id,
        this.form.deliveredBy.type,
        this.form.courier.driverId,
        this.form.listSttNoToUpdate?.map((item: any) => item.sttNo) || [],
        this.form.deliveryBy?.type === 'vendor' || this.form.dataType === 'Tidak' ? 'GENESIS' : 'ALGO'
      )
    );
    if (
      withPrint &&
      responseCreate &&
      (responseCreate as CreateUpdateDeliveryResponse).delId
    ) {
      // to call print with del id
      // views -> incoming -> delivery manifest -> modules
      const print: any = this.$refs.print;
      print.printUpdateDel(
        (responseCreate as CreateUpdateDeliveryResponse).delId
      );
    }

    // handle popup success, partial, failed
    if (responseCreate) {
      MainAppController.showMessageModal(this.responseData(responseCreate));
      UpdateDeliveryController.setSttFromLocalStorage([]);
    }
  }
  responseData(
    responseCreate: CreateUpdateDeliveryResponse | boolean
  ): ModalMessageEntities {
    const totalSuccess = (responseCreate as CreateUpdateDeliveryResponse)
      .totalSuccess;
    const totalFailed = (responseCreate as CreateUpdateDeliveryResponse)
      .totalFailed;
    const successData = new ModalMessageEntities({
      title: "Pembaruan Berhasil !",
      totalSuccess,
      totalFailed,
      textSuccess: "OK",
      onSubmit: this.onSubmitSuccessModal
    });
    const customWidth = "380px";

    if (totalSuccess && totalFailed) {
      return new ModalMessageEntities({
        ...successData,
        message:
          "Beberapa status STT berhasil diperbarui menjadi DEL,<br />namun terdapat kegagalan pada beberapa STT.<br />Silahkan download untuk mengatahui kesalahan.",
        image: "image-modal-warning",
        customWidth: "402px"
      });
    }
    if (totalSuccess) {
      return new ModalMessageEntities({
        ...successData,
        message: "Status STT berhasil diperbarui menjadi DEL.",
        image: "image-modal-success",
        customWidth
      });
    }
    return new ModalMessageEntities({
      title: "Pembaruan Gagal !",
      message: "Status DEL gagal diperbarui.",
      image: "image-modal-failed",
      textSuccess: "Download",
      textCancel: "OK",
      onSubmit: () =>
        this.downloadCsvFailedStt({
          fileName: "Update_Del_STT_Failed.csv",
          listStt: (responseCreate as CreateUpdateDeliveryResponse).sttFailed
        }),
      onClose: this.onSubmitSuccessModal,
      customWidth
    });
  }

  onSubmitSuccessModal() {
    MainAppController.closeMessageModal();
    this.answerLeavingPage = true;
    removeDataLocalStorage("update-delivery");
    this.goBack();
  }

  get isLoading() {
    return UpdateDeliveryController.isLoading;
  }
}
