
import { Vue, Options } from "vue-class-component";
import OverlayPanel from "primevue/overlaypanel";
import UpdateDeliveryFormDeleteStt from "@/app/ui/views/incoming-process/update-delivery/update-delivery-form/update-delivery-form-delete-stt.vue";
import { UpdateDeliveryController } from "@/app/ui/controllers/UpdateDeliveryController";
import { ellipsisString, formatPrice } from "@/app/infrastructures/misc/Utils";
import { SttDetail } from "@/domain/entities/UpdateDelivery";

@Options({
  emits: ["process"],
  components: {
    OverlayPanel,
    UpdateDeliveryFormDeleteStt
  }
})
export default class UpdateDeliveryFormTableStt extends Vue {
  pagination = {
    page: 1,
    limit: 50
  };
  // panel button process
  togglePanel(event: any) {
    const refs: any = this.$refs;
    refs.op.toggle(event);
  }
  get form() {
    return UpdateDeliveryController.form;
  }
  get isFormValid() {
    return (
      this.form.deliveredBy.id &&
      this.form.deliveredBy.type &&
      this.form.courier.driverId
    );
  }

  // get data needed
  get listStt() {
    return UpdateDeliveryController.form.listSttNoToUpdate;
  }
  get totalStt() {
    return this.listStt.length;
  }
  get calculatedStt() {
    return this.listStt.reduce(
      (a: any, b: any) => {
        return {
          totalPieces: a.totalPieces + b.sttPieces.length,
          totalGrossWeight: a.totalGrossWeight + b.sttGrossWeight,
          totalVolumeWeight: a.totalVolumeWeight + b.sttVolumeWeight
        };
      },
      {
        totalPieces: 0,
        totalGrossWeight: 0,
        totalVolumeWeight: 0
      }
    );
  }
  get totalSttPieces() {
    return this.calculatedStt.totalPieces;
  }
  get totalSttGrossWeight() {
    return this.formatDecimal(this.calculatedStt.totalGrossWeight);
  }
  get totalSttVolumeWeight() {
    return this.formatDecimal(this.calculatedStt.totalVolumeWeight);
  }
  formatDecimal(val: number) {
    return val.toLocaleString("id", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    });
  }

  // table
  columns = [
    {
      name: "No.",
      styleHead: "w-12 text-center whitespace-no-wrap",
      render: (item: SttDetail, index: number) => {
        return `<div class="text-black-lp-300">${index +
          1 +
          this.pagination.limit * (this.pagination.page - 1)}</div>`;
      }
    },
    {
      name: `No. STT`,
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: (item: SttDetail) => {
        return `<div class="text-black-lp-300">${item.sttNo}</div>`;
      }
    },
    {
      name: `No. Referensi`,
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: (item: SttDetail) => {
        return `<div class="text-black-lp-300">${item.sttNoRefExternal || "-"}</div>`;
      }
    },
    {
      name: "Pengiriman Ke",
      styleHead: "w-32 text-left whitespace-no-wrap",
      render: (item: SttDetail) => {
        return `<div class="text-black-lp-300">${item.sttDeliveryAttempt}</div>`;
      }
    },
    {
      name: "Biaya COD",
      styleHead: "w-28 text-left whitespace-no-wrap",
      render: (item: SttDetail) => {
        return `<div class="text-black-lp-300">${
          item.sttCodAmount ? formatPrice(item.sttCodAmount) : "-"
        }</div>`;
      }
    },
    {
      name: "Nama Penerima",
      styleHead: "w-52 text-left whitespace-no-wrap",
      render: (item: SttDetail) => {
        return `<div class="text-black-lp-300">${item.sttRecipientName}</div>`;
      }
    },
    {
      name: "Alamat Penerima",
      styleHead: "w-64 text-left whitespace-no-wrap",
      render: (item: SttDetail) => {
        return `<p class="text-black-lp-300 break-all">${ellipsisString(
          item.sttRecipientAddress,
          30
        )}</p>`;
      }
    },
    {
      name: "Dest.",
      styleHead: "w-20 text-left whitespace-no-wrap",
      render: (item: SttDetail) => {
        return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.sttDestinationCityCode}
                  </div>
                </div>`;
      }
    },
    {
      name: "No. Telp Penerima",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: (item: SttDetail) => {
        return `<div class="text-black-lp-300">${item.sttRecipientPhone}</div>`;
      }
    },
    {
      name: "Produk",
      styleHead: "w-32 text-left whitespace-no-wrap",
      render: (item: SttDetail) => {
        return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.sttProductType}
                  </div>
                </div>`;
      }
    },
    {
      name: "Komoditas",
      styleHead: "w-48 text-left whitespace-no-wrap",
      render: (item: SttDetail) => {
        return `<div class="text-black-lp-300">${item.sttCommodityName}</div>`;
      }
    },
    {
      name: "Koli",
      styleHead: "w-20 text-left whitespace-no-wrap",
      render: (item: SttDetail) => {
        return `<div class="text-black-lp-300 flex">
                  ${item.sttTotalPiece}
                </div>`;
      }
    },
    {
      name: "Berat Kotor",
      styleHead: "w-36 text-left whitespace-no-wrap",
      render: (item: SttDetail) => {
        return `<div class="text-black-lp-300">${this.formatDecimal(
          item.sttGrossWeight
        )} Kg</div>`;
      }
    },
    {
      name: "Berat Dimensi",
      styleHead: "w-36 text-left whitespace-no-wrap",
      render: (item: SttDetail) => {
        return `<div class="text-black-lp-300">${this.formatDecimal(
          item.sttVolumeWeight
        )} Kg</div>`;
      }
    },
    {
      name: "Berat Dikenakan Biaya",
      styleHead: "w-48 text-left whitespace-no-wrap",
      render: (item: SttDetail) => {
        return `<div class="text-black-lp-300">${this.formatDecimal(
          item.sttChargeAbleWeight
        )} Kg</div>`;
      }
    }
  ];

  // dialog delete stt
  openDeleteStt = false;
  onCloseDeleteStt() {
    this.openDeleteStt = false;
  }

  // on process del
  onProcessDel(withPrint: boolean) {
    const refs: any = this.$refs;
    refs.op.hide();
    this.$emit("process", withPrint);
  }
}
