
import { AccountController } from "@/app/ui/controllers/AccountController";
import { Vue } from "vue-class-component";
import generateManifest from "./manifest-pdf";
import { UpdateDeliveryController } from "@/app/ui/controllers/UpdateDeliveryController";
import { formatPrice } from "@/app/infrastructures/misc/Utils";
import { PrintData } from "@/domain/entities/UpdateDelivery";
import { MainAppController } from "@/app/ui/controllers/MainAppController";

export default class Print extends Vue {
  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }
  async printUpdateDel(id: number) {
    MainAppController.showLoading();
    const printProcess = await UpdateDeliveryController.printDelivery({
      id: id
    });

    if (printProcess) {
      const dataPrint: PrintData = UpdateDeliveryController.printDataDelivery;
      // logo
      const element: any = document;
      const logo = element.getElementById("logo").src;

      // mapping data
      const data: any = [];
      let tempNo = 0;

      dataPrint.stt.forEach(stt => {
        tempNo = tempNo + 1;
        data.push({
          no: `${tempNo}`,
          sttNo: stt.sttNo,
          sttNoElexys: stt.sttNoElexys,
          productType: stt.productType,
          totalPiece: stt.totalPiece,
          recipientName: `${stt.recipientName}\n${stt.recipientAddress}\n${stt.recipientPhone}`,
          nameSignature: "",
          receivedTime: "",
          information: "",
          sttCodAmount: formatPrice(stt.codAmount),
          origin: stt.origin,
          destination: stt.destination
        });
      });

      await generateManifest(dataPrint, data, logo);
    }
    MainAppController.closeLoading();
  }

  // role account user
  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }
}
