/* eslint-disable @typescript-eslint/camelcase */
import { getHubData } from "@/app/infrastructures/misc/Cookies";
import { UpdateDeliveryRequest } from "../contracts/UpdateDeliveryRequest";

export class CreateUpdateDelivery implements UpdateDeliveryRequest {
  delivered_by: number;
  delivered_type: string;
  driver_id: number;
  stt_no: string[];
  driver_source:string;
  constructor(
    delivered_by: number,
    delivered_type: string,
    driver_id: number,
    stt_no: string[],
    driver_source: string
  ) {
    this.delivered_by = delivered_by;
    this.delivered_type = delivered_type;
    this.driver_id = driver_id;
    this.stt_no = stt_no;
    this.driver_source = driver_source
  }

  toJSON() {
    return JSON.stringify({
      delivered_by: this.delivered_by,
      delivered_type: this.delivered_type,
      driver_id: this.driver_id,
      stt_no: this.stt_no,
      hub_id: getHubData()?.hubId || 0,
      hub_name: getHubData()?.hubName || "",
      hub_origin_city: getHubData()?.originCity || "",
      hub_district_code: getHubData()?.hubDistrictCode || "",
      driver_source: this.driver_source
    });
  }
}
