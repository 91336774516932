
import { Options, Vue } from "vue-class-component";
import CustomDropdown from "@/app/ui/components/custom-dropdown/index.vue";
import RadioButton from "primevue/radiobutton";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { VendorController } from "@/app/ui/controllers/VendorController";
import { DeliveryVendor } from "@/domain/entities/Vendor";
import debounce from "lodash/debounce";

@Options({
  emits: ["change", "update:modelValue", 'onChangeRadiobutton'],
  components: {
    CustomDropdown,
    RadioButton
  },
  props: {
    modelValue: {
      default: "",
      required: true
    },
    disabled: {
      default: false,
      type: Boolean
    }
  }
})
export default class ComponentSelectDeliveredBy extends Vue {
  props: any = this.$props;
  isExpand = true;

  radioValue = "";
  onChangeRadioValue() {
    this.$emit("update:modelValue", "");
    this.$emit("onChangeRadiobutton", this.radioValue);
  }

  // account profile for console & sub console
  get profileData() {
    return AccountController.accountData;
  }
  get role() {
    return AccountController.accountData.account_type_detail.type;
  }

  // select delivered by
  get deliveredByConsoleSubConsoleOptions() {
    // id & type is mandatory
    return [
      {
        id: AccountController.accountData.account_type_detail.id,
        code: AccountController.accountData.account_type_detail.code,
        name: AccountController.accountData.account_type_detail.company_name,
        type: AccountController.accountData.account_type_detail.type
      }
    ];
  }
  deliveredByVendorList: DeliveryVendor[] = [];
  loadingVendorList = true;
  get deliveredByVendorOptions() {
    return this.deliveredByVendorList.map((item: DeliveryVendor) => {
      // id & type is mandatory
      return {
        id: item.deliveryVendorId,
        code: item.deliveryVendorName,
        name: item.deliveryVendorName,
        type: "vendor"
      };
    });
  }
  fetchDeliveryVendorOptions = debounce(async (search: string) => {
    this.loadingVendorList = true;
    this.deliveredByVendorList = await VendorController.getDeliveryVendorList({
      page: 1,
      limit: 7,
      search: search
    });
    this.loadingVendorList = false;
  }, 500);
  onUpdateDeliveredBy(value: any) {
    this.$emit("update:modelValue", value);
    this.$emit("onChangeRadiobutton");
  }

  // post new vendor
  openAddVendor = false;
  newVendorName = "";
  handleOpenAddVendor(bool: boolean) {
    this.openAddVendor = bool;
  }
  clearNewVendorName() {
    this.newVendorName = "";
    this.handleOpenAddVendor(false);
  }
  async submitNewVendorName() {
    if (this.newVendorName) {
      // to do submit new vendor
      const resp = await VendorController.addDeliveryVendor({
        deliveryVendorName: this.newVendorName
      });
      if (resp.success) {
        const refs: any = this.$refs;
        refs.selectSearchVendor.filterSearch = "";
        this.fetchDeliveryVendorOptions("");
      }
      this.clearNewVendorName();
    }
  }
}
