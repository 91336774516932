
import { Vue, Options } from "vue-class-component";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import Print from "./modules/print.vue";
import { UpdateDeliveryController } from "@/app/ui/controllers/UpdateDeliveryController";
import { UpdateDelData } from "@/domain/entities/UpdateDelivery";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";

@Options({
  components: {
    EmptyState,
    Print
  },
  computed: {
    getTitle() {
      return this.$route.meta.name;
    }
  }
})
export default class UpdateDel extends Vue {
  mounted() {
    this.refs = this.$refs;
  }

  get ableToCreate() {
    return true;
  }

  refs: any = "";

  fetchList() {
    UpdateDeliveryController.fetchList();
  }

  // loading
  get isLoading() {
    return UpdateDeliveryController.isLoading;
  }

  // filter date
  get startDate() {
    return UpdateDeliveryController.filter.startDate;
  }

  get endDate() {
    return UpdateDeliveryController.filter.endDate;
  }

  setDateRange(value: Array<any>) {
    UpdateDeliveryController.setStartDate(value[0]);
    UpdateDeliveryController.setEndDate(value[1]);
    value[0] && value[1] && this.fetchList();
  }

  // Table
  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (_: UpdateDelData, index: number) => {
          return `<div class="overflow-ellipsis text-left">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "Total STT",
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: UpdateDelData) => {
          return `<div class="overflow-ellipsis text-left">${item.deliveryTotalStt}</div>`;
        }
      },
      {
        name: "Total Koli",
        styleHead: "w-28 text-left whitespace-no-wrap",
        render: (item: UpdateDelData) => {
          return `<div class="overflow-ellipsis text-left">${item.deliveryTotalPiece}</div>`;
        }
      },
      {
        name: "Nama Kurir",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: UpdateDelData) => {
          return `<div class="overflow-ellipsis text-left">${item.deliveryDriverName}</div>`;
        }
      },
      {
        name: "No. Telp Kurir",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: UpdateDelData) => {
          return `<div class="overflow-ellipsis text-left">${item.deliveryDriverPhone}</div>`;
        }
      },
      {
        name: "Dikirim Oleh",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: UpdateDelData) => {
          return `<div class="overflow-ellipsis text-left">${ellipsisString(
            item.deliveryDeliveredBy,
            52
          )}</div>`;
        },
        vtooltip: (item: any) =>
          item.deliveryDeliveredBy?.length > 52 ? item.deliveryDeliveredBy : ""
      },
      {
        name: "Tanggal Dibuat",
        styleHead: "w-40 text-left",
        render: (item: UpdateDelData) => {
          return `<div class="text-black-lp-300">${formatDateWithoutTime(
            item.deliveryCreatedAt
          )}</div>
            <div class="capitalize text-gray-lp-500">${ellipsisString(
              item.deliveryCreatedBy,
              14
            )}</div>`;
        }
      },
      {
        name: "Action",
        key: "actionable_column",
        styleHead: "w-32 text-left",
        showButton: () => {
          return true;
        }
      }
    ];
  }

  // custom button
  customButton = {
    outline: true,
    title: "Print",
    textColor: "black-lp-300",
    color: "gray-lp-400",
    small: false,
    clickFunction: (item: any) => this.refs.print.printUpdateDel(item.id)
  };

  get pagination() {
    return UpdateDeliveryController.listData.pagination;
  }

  get listData() {
    return UpdateDeliveryController.listData.data;
  }

  // Update
  onUpdate() {
    this.$router.push("/update-delivery/update");
  }

  // handle error
  get errorType() {
    return UpdateDeliveryController.errorType;
  }
}
