import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pt-8 bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative w-full" }
const _hoisted_2 = { class: "flex z-20" }
const _hoisted_3 = { class: "font-semibold self-center mr-10 text-20px whitespace-no-wrap text-black-lp-200 capitalize" }
const _hoisted_4 = {
  key: 1,
  class: "overflow-auto pb-16 h-full"
}
const _hoisted_5 = { class: "hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_time_range_picker = _resolveComponent("time-range-picker")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_Header = _resolveComponent("Header")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_Print = _resolveComponent("Print")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_Breadcrumb),
      _createVNode(_component_Header, { class: "items-center" }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_2, [
            _createVNode("div", _hoisted_3, _toDisplayString(_ctx.getTitle), 1),
            _createVNode(_component_time_range_picker, {
              startDate: _ctx.startDate,
              endDate: _ctx.endDate,
              onSelect: _ctx.setDateRange,
              showOptions: "custom, hari ini, 7 hari terakhir, 14 hari terakhir"
            }, null, 8, ["startDate", "endDate", "onSelect"])
          ]),
          (_ctx.ableToCreate)
            ? (_openBlock(), _createBlock(_component_LpButton, {
                key: 0,
                onClick: _ctx.onUpdate,
                title: "Update DEL",
                textColor: "white"
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (
        _ctx.listData.length === 0 &&
          !_ctx.startDate &&
          !_ctx.endDate &&
          !_ctx.isLoading &&
          !_ctx.errorType
      )
        ? (_openBlock(), _createBlock(_component_EmptyState, {
            key: 0,
            data: "delivery"
          }))
        : (_openBlock(), _createBlock("div", _hoisted_4, [
            _createVNode(_component_TableV2, {
              columns: _ctx.columns,
              data: _ctx.listData,
              loading: _ctx.isLoading,
              pagination: _ctx.pagination,
              "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
              totalColumnFreeze: 1,
              isRightFreeze: true,
              onRequest: _ctx.fetchList,
              errorCause: _ctx.errorType,
              onTryAgain: _ctx.fetchList,
              customButtonAction: _ctx.customButton
            }, null, 8, ["columns", "data", "loading", "pagination", "onRequest", "errorCause", "onTryAgain", "customButtonAction"])
          ]))
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode(_component_Print, { ref: "print" }, null, 512)
    ])
  ], 64))
}